/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { TextField, CircularProgress } from '@material-ui/core'
import { passRestore, getRestoreToken } from '@root-auth/store/actions/auth/passRestore'
import { passRecall } from '@root-auth/store/actions/auth/passRecall'
import MuiButton from '../MuiComponents/MuiButton'

interface IRestore {
	passRestore: (password: string, token: string) => void
	getRestoreToken: (token: string) => void
	passRecall: (login: string) => void
	restoreToken: string
	newPasswordSuccess: boolean
}

const Restore: React.FC<IRestore> = ({
	passRestore,
	getRestoreToken,
	restoreToken,
	passRecall,
	newPasswordSuccess,
}) => {
	const [login, setLogin] = useState('')
	const [password, setPassword] = useState('')
	const [repeatPassword, setRepeatPassword] = useState('')
	const [errorRepeatPassword, setErrorRepeatPassword] = useState(false)
	const cutToken = window.location.toString().match(/[^\/]*$/)!

	useEffect(() => {
		if (cutToken) getRestoreToken(cutToken[0])
	}, [])

	useEffect(() => {
		if (repeatPassword && repeatPassword !== password) {
			setErrorRepeatPassword(true)
		} else {
			setErrorRepeatPassword(false)
		}
	}, [repeatPassword, password])

	useEffect(() => {
		if (password === '') setRepeatPassword('')
	}, [password])

	return (
		<div className='login'>
			<Link to='/'>
				<img className='login__image' src='/assets/images/SVG/logo.svg' alt='LOGO' />
			</Link>
			<form
				className='login__form'
				onSubmit={e => {
					e.preventDefault()
					if (restoreToken) {
						return passRestore(password, restoreToken)
					} else {
						return passRecall(login)
					}
				}}
			>
				{restoreToken === null ? (
					<>
						<CircularProgress />
					</>
				) : restoreToken && !newPasswordSuccess ? (
					<>
						<h2 className='login__title'>Введите новый пароль</h2>
						<div className='login__fields'>
							<TextField
								label='Новый пароль'
								variant='outlined'
								fullWidth
								type='password'
								name='new-password'
								autoComplete='off'
								value={password}
								onChange={e => setPassword(e.currentTarget.value)}
								inputProps={{ inputMode: 'text' }}
								required
							/>
							<TextField
								label='Повторите пароль'
								variant='outlined'
								fullWidth
								type='password'
								name='repeat-new-password'
								autoComplete='off'
								value={repeatPassword}
								onChange={e => setRepeatPassword(e.currentTarget.value)}
								inputProps={{ inputMode: 'text' }}
								disabled={password.trim() === '' ? true : false}
								required
								error={errorRepeatPassword}
								helperText={errorRepeatPassword && 'Пароли не совпадают'}
							/>
						</div>
						<div className='login__actions'>
							<MuiButton
								disabled={password && repeatPassword && !errorRepeatPassword ? false : true}
								fullWidth
								variant='contained'
								color='primary'
								type='submit'
							>
								Сохранить
							</MuiButton>
							<div className='login__actions-item'>
								Уже есть аккаунт?{' '}
								<Link to='/login' className='link-blue'>
									Войти
								</Link>
							</div>
						</div>
					</>
				) : restoreToken === undefined ? (
					<>
						<h2 className='login__title'>Ссылка устарела, пожалуйста, сделайте новый запрос</h2>
						<div className='login__fields'>
							<TextField
								label='Email'
								variant='outlined'
								fullWidth
								type='email'
								name='email'
								autoComplete='off'
								onChange={e => setLogin(e.currentTarget.value)}
								onFocus={e => e.currentTarget.removeAttribute('readonly')}
								value={login}
								inputProps={{ readOnly: true }}
							/>
						</div>
						<div className='login__actions'>
							<MuiButton disabled={login ? false : true} fullWidth variant='contained' color='primary' type='submit'>
								Отправить запрос заново
							</MuiButton>
							<div className='login__actions-item'>
								Уже есть аккаунт?{' '}
								<Link to='/login' className='link-blue'>
									Войти
								</Link>
							</div>
						</div>
					</>
				) : (
					<>
						<h2 className='login__title'>Новый пароль сохранен!</h2>
						<div className='login__actions'>
							<Link to='/login' className='link link-white'>
								<MuiButton fullWidth variant='contained' color='primary'>
									Войти в систему
								</MuiButton>
							</Link>
						</div>
					</>
				)}
			</form>
		</div>
	)
}

const mapStateToProps = (state: any) => ({
	newPasswordSuccess: state.auth.newPasswordSuccess,
	restoreToken: state.auth.restoreToken,
})

const mapDispatchToProps = (dispatch: any) => ({
	passRecall: (login: string) => dispatch(passRecall(login)),
	passRestore: (password: string, token: string) => dispatch(passRestore(password, token)),
	getRestoreToken: (token: string) => dispatch(getRestoreToken(token)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Restore)
